import React, { useState, useEffect, useCallback } from 'react';
import { Button } from '../form';

interface ButtonWithTimeProps {
  onClick: () => void;
  initialTime: number;
  buttonText: string;
  className?: string;
}

export const ButtonWithTime: React.FC<ButtonWithTimeProps> = ({
  onClick,
  initialTime,
  buttonText,
  className
}) => {
  const [countdownTime, setCountdownTime] = useState<number | null>(null);

  useEffect(() => {
    let interval: NodeJS.Timeout | null = null;
    if (countdownTime !== null && countdownTime > 0) {
      interval = setInterval(() => {
        setCountdownTime((prevTime) =>
          prevTime !== null ? prevTime - 1 : null
        );
      }, 1000);
    } else if (countdownTime === 0) {
      setCountdownTime(null);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [countdownTime]);

  const handleClick = useCallback(() => {
    onClick();
    setCountdownTime(initialTime);
  }, [onClick, initialTime]);

  const buttonTitle =
    countdownTime !== null && countdownTime > 0
      ? `${buttonText} (${countdownTime})`
      : buttonText;

  return (
    <Button
      type="button"
      onClick={handleClick}
      disabled={countdownTime !== null}
      className={className || 'px-2 py-2 w-48'}
    >
      {buttonTitle}
    </Button>
  );
};
