import { useAppDispatch, useAppSelector } from '../../state';
import {
  setPaperCommunitySortMethod,
  setPaperTopicSortMethod
} from '../../state/actions/sortingActions';
import {
  PAPER_COMMUNITY_SORT_METHODS,
  PAPER_TOPIC_SORT_METHODS,
  PaperCommunitySortMethod,
  PaperTopicSortMethod
} from '../../utils/sortingConstants';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import qs from 'qs';
import { PapersCommunityList } from './papers-community-list';
import { PapersTopicList } from './papers-topic-list';
import { SortingButtons } from '../sortingButtons';
import { ViewModeToggle } from '../viewModeToggle';
import { PapersFilterList } from './papers-filter-list';
import { useQueryParams } from '../../hooks/useQueryParams';
import { useFilterPagination } from './hooks/useFilterPagination';
import { selectPapers } from '../../state/selectors/papersSelector';

interface PapersContentProps {
  isCommunity: boolean;
}

export const PapersList: FC<PapersContentProps> = ({ isCommunity }) => {
  const dispatch = useAppDispatch();
  const { urlKey } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const viewMode = useAppSelector((state) => state.viewmode.mode);
  const isTripleView = viewMode === 'triple';

  const papersSortMethod = useAppSelector((state) =>
    isCommunity
      ? state.sort.paperCommunitySortMethod
      : state.sort.paperTopicSortMethod
  );

  const queryParams = useQueryParams();
  const initialPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
  const [currentPaperPage, setCurrentPaperPage] = useState(initialPage);
  const { updatePageForFilter } = useFilterPagination(isCommunity, urlKey);

  const papers = useAppSelector(selectPapers);

  const isFilterActive = Boolean(
    queryParams.title ||
      queryParams.author ||
      queryParams.journal ||
      queryParams.dateFrom ||
      queryParams.dateTo
  );

  const updatePage = (newPage: number) => {
    setCurrentPaperPage(newPage);
    const newSearch = qs.stringify({ p: newPage }, { addQueryPrefix: true });
    navigate({ pathname: location.pathname, search: newSearch });
  };

  const handleSortingPapersChange =
    (method: PaperCommunitySortMethod | PaperTopicSortMethod) => () => {
      if (isCommunity) {
        dispatch(
          setPaperCommunitySortMethod(method as PaperCommunitySortMethod)
        );
      } else {
        dispatch(setPaperTopicSortMethod(method as PaperTopicSortMethod));
      }
    };

  useEffect(() => {
    const newPage = queryParams.p ? parseInt(queryParams.p as string, 10) : 1;
    setCurrentPaperPage(newPage);
  }, [location.search, queryParams]);

  return (
    <div className="w-full h-full">
      {papers.page?.length !== 0 || !isFilterActive ? (
        <>
          <SortingButtons
            sortMethods={
              isCommunity
                ? PAPER_COMMUNITY_SORT_METHODS
                : PAPER_TOPIC_SORT_METHODS
            }
            currentSortMethod={papersSortMethod}
            onSortMethodChange={(page) => handleSortingPapersChange(page)}
          />
          <ViewModeToggle />
        </>
      ) : (
        ''
      )}
      {isFilterActive ? (
        // Render filtered results if filters are active
        <PapersFilterList
          isCommunity={isCommunity}
          updatePage={updatePageForFilter}
          isTripleView={isTripleView}
        />
      ) : // Render default list if no filters are active
      isCommunity ? (
        <PapersCommunityList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentPaperPage}
        />
      ) : (
        <PapersTopicList
          updatePage={updatePage}
          urlKey={urlKey}
          isTripleView={isTripleView}
          currentDiscussionPage={currentPaperPage}
        />
      )}
    </div>
  );
};
