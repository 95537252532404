import React from 'react';
import { ArrowDown } from '../../../svgs';
import { SearchFieldContainer } from '../../../containers/core/search-field-container';
import { Footer } from '../footer';
import { MenuBurgerButton } from '../../../svgs/MenuBurgerButton';
import { UserPostMetaData } from '../../../routes/post/user-post-meta-data';
import { MainLogo, TopBarModals } from '..';
import { AuthButtons } from './auth';
import { NewsTicker } from '../../newsTicker';
import { useTopBar } from './hooks/useTopBar';

export interface Props {
  children: React.ReactNode;
}

export const PostTopBar: React.FC<Props> = (props) => {
  const {
    user,
    showDropdown,
    isMenuVisible,
    handleToggleDropdown,
    handleHideDropdown,
    handleLogoutClick,
    handleCloseModal,
    handleMouseDown,
    toggleVisibility
  } = useTopBar();

  const { children } = props;

  return (
    <div className="tablet:flex-no-wrap  flex-no-wrap w-full flex sticky top-0 z-50">
      <div
        className={`tablet:flex-no-wrap h-auto flex-col mr-0 top-bar-left flex justify-start h-full hamburger-menu ${
          isMenuVisible ? 'showMenu' : ''
        }`}
      >
        <div className="top-bar-border-bottom pt-4 pb-4 px-4">
          <MainLogo />
          <div className="relative z-10 flex  flex-grow ">
            {user ? (
              <div className="relative w-full pt-4">
                <button
                  className="ml-auto w-full flex justify-between items-center"
                  onClick={handleToggleDropdown}
                  onBlur={handleHideDropdown}
                >
                  <span className="mr-1 userName">{user.displayName}</span>
                  <span className="">
                    <ArrowDown className="opacity-50" />
                  </span>
                </button>
                {showDropdown && (
                  <div
                    className="top-full absolute right-0 mt-2 text-sm font-medium"
                    onMouseDown={handleMouseDown}
                  >
                    <div
                      className="hover:text-primary-500 px-4 py-2 transition-colors duration-200 bg-white shadow-md cursor-pointer"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <AuthButtons />
            )}
          </div>
        </div>
        <div className="h-full flex flex-col justify-between">
          <div className="px-4 pt-4 ">
            <UserPostMetaData />
          </div>

          <div className="">
            <Footer />
          </div>
        </div>
      </div>
      <div className="w-full relative min-h-screen bg-silv main-page h-full">
        <div>
          <NewsTicker />
        </div>
        <div className="flex justify-center pt-3 pb-3 tablet:px-4 px-2 bg-white">
          <SearchFieldContainer />
          <div className="menu-button">
            <button onClick={toggleVisibility}>
              <MenuBurgerButton />
            </button>
          </div>
        </div>
        <div className="tablet:mt-0 tabsContainer tablet:order-none relative z-10 flex justify-center flex-grow order-last w-full ">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
      <TopBarModals closeModal={handleCloseModal} />
    </div>
  );
};
