import React, { useCallback, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { paperPublishedMonthYear } from '../../utils/month-formatter';
import { HasLinks } from '../../svgs';
import { thousandSeparator } from '../../utils/numbers-formatter';
import { analytics } from '../../analytics';
import { useGetCommentsbyIdData } from '../../api/dataLayer';
import { ShareButton } from '../share';
import { CommentPost } from '../../svgs/CommentPost';
import { DatePost } from '../../svgs/DatePost';
import { useCommentCount } from '../discussion';
import ArticleDiscussions from '../../svgs/ArticleDiscussions';
import { RenderHTML } from './render-html';
import { ApiPaperPreview } from '../../api/oipub-api';
import { createLink } from '../../utils/helperFunctions';
import { IPaper } from '../../types/paper.type';

export interface Props {
  paper: IPaper;
  trackingLabel?: 'search' | 'feed' | 'topic';
  isDiscussion?: boolean;
  showThreeItems?: boolean;
}

export const Previewv2: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { paper } = props;
  const [commentCount, setCommentCount] = useState<number | null>(null);
  const { commentsData } = useGetCommentsbyIdData(
    'commentSectionId' in paper ? paper.commentSectionId : null
  );
  const computedCommentCount = useCommentCount(commentsData);
  useEffect(() => {
    if ('commentSectionId' in paper) {
      setCommentCount(computedCommentCount);
    } else {
      const previewPaper = paper as ApiPaperPreview;
      if ('numComments' in previewPaper) {
        setCommentCount(previewPaper.numComments as number);
      }
    }
  }, [paper, computedCommentCount]);

  function handleOpenPaper() {
    if (!!props.trackingLabel) {
      analytics.events.openPaper(props.trackingLabel);
    }
  }

  function findMatchesInAbstract(text: string): string {
    const sentences = text.match(
      /[^.!?]*<span class="highlight">.*?<\/span>[^.!?]*[.!?]/g
    );

    if (!sentences) return '';

    return sentences
      .map((sentence, i, arr) => {
        let formattedSentence = sentence.trim();
        if (i > 0) {
          formattedSentence = '...' + formattedSentence; // not the first one
        }
        if (i < arr.length - 1) {
          formattedSentence += '...\n'; // not the last one
        }
        return formattedSentence;
      })
      .join('');
  }

  const handleTopicTagClick = useCallback(
    (
      event: React.MouseEvent<HTMLSpanElement, MouseEvent>,
      urlKey: string | null | undefined
    ) => {
      event.preventDefault();
      event.stopPropagation();
      if (urlKey) {
        navigate(createLink(`/topic/${urlKey}`));
      }
    },
    [navigate]
  );

  let renderedLinksAvailable: JSX.Element | null = null;
  if (paper.links && paper.links.length > 0) {
    renderedLinksAvailable = (
      <div
        className="text-primary-500 inline-block w-4 h-4 ml-2"
        title="Links available"
      >
        <HasLinks className="link_avaliable" />
      </div>
    );
  }

  const renderedTopicsKeywords: JSX.Element[] = [];
  const showTopicsKeywordsCount = 2;
  const AbstractFirstSentence =
    (props.paper.abstract?.match(/(.*?[.!?])\s/) || [])[0] || '';

  if (paper.tags) {
    let i = 0;
    for (const tag of paper.tags.slice(0, showTopicsKeywordsCount)) {
      if (renderedTopicsKeywords.length < showTopicsKeywordsCount) {
        if (tag && tag.urlKey && tag.id) {
          renderedTopicsKeywords.push(
            <span
              role="button"
              onClick={(e) => handleTopicTagClick(e, tag.urlKey)}
              className="text-ssm mr-1 text-primary-500 hover:text-primary-400 active:text-primary-200 focus:outline-none focus:shadow-outline leading-none mr-1 mb-2 transition-colors duration-200 text-center"
              key={tag.id}
              style={{ cursor: 'pointer' }}
            >
              {tag.name}
              {i < paper.tags.length - 1 ? ', ' : ' '}
            </span>
          );
          i++;
        }
      }
    }
  }

  let url = createLink('/paper');
  if (paper?.id) {
    url += `/${paper.id}`;
  }
  const currentUrl = window.location.origin;
  const fullUrl = currentUrl + url;

  const numOfTopicsKeywords = paper.tags ? paper.tags.length : 0;

  return (
    <div className="border-b-gray-400 last:border-b-0 hover:bg-primary-100 block w-full max-w-3xl mx-auto transition-colors duration-200 border-b">
      <Link
        to={url}
        onClick={handleOpenPaper}
        className={`item w-full ${props.isDiscussion ? 'discussion-class' : ''}`}
      >
        {props.isDiscussion && (
          <div className="disc_item_icon">
            <ArticleDiscussions className="discussion_icon" />
          </div>
        )}

        <div className="itemTitle font-bold flex mb-3 cursor-pointer">
          <div className="text-sm text-gray-900 hover:text-primary-500 transition-smooth-color leading-tight flex">
            <RenderHTML htmlContent={paper.title} />
            {renderedLinksAvailable}
            {/* {renderedDiscussionBadge} */}
          </div>
        </div>
        {paper?.authors?.length && paper.authors.length > 0 && (
          <div className="authorDate color-itemContent font-roboto text-ssm mb-3">
            <span className="text-yellow opacity-1">
              <RenderHTML
                htmlContent={`
                By&nbsp;${paper.authors.map((author) => author.name).join('; ')}
                `}
              />
            </span>
          </div>
        )}
        {AbstractFirstSentence && (
          <div className="itemContent color-itemContent text-itemContent mb-5">
            <RenderHTML
              htmlContent={
                findMatchesInAbstract(props.paper.abstract!) ||
                AbstractFirstSentence
              }
            />
          </div>
        )}
        <hr />
        <div
          className={`flex justify-between mt-4 ${props.showThreeItems ? 'flex-col-reverse' : ''}`}
        >
          <div className="itemActions flex justify-between items-center text-black">
            {paper.publishedYear ? (
              <div className="itemActionDate">
                <div className="flex items-center">
                  <DatePost />
                  <span className="text-half ml-1">
                    {paperPublishedMonthYear(
                      paper.publishedYear,
                      paper.publishedMonth
                    )}
                  </span>
                </div>
              </div>
            ) : null}
            <div className="itemActionComments">
              <div className="flex items-center">
                <CommentPost className="flex-shrink-0" />
                <span className="text-half w-full ml-1">{commentCount}</span>
              </div>
            </div>
            <div className="itemActionShare">
              <div className="flex items-center">
                <ShareButton url={fullUrl} />
              </div>
            </div>
          </div>
          <div
            className={`authorLink font-roboto text-half ${props.showThreeItems ? 'mb-4' : ''}`}
          >
            {renderedTopicsKeywords}
            {numOfTopicsKeywords > 2 && (
              <span className="text-gray-400">
                + {thousandSeparator(numOfTopicsKeywords - 2)} {'more'}
              </span>
            )}
          </div>
        </div>
      </Link>
    </div>
  );
};
