import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../state';
import { setPapersDiscussionsRadioState } from '../../../state/slices/tabsReducer';
import { createLink } from '../../../utils/helperFunctions';

interface UsePapersDiscussionsTabsProps {
  contentUrlKey: string;
  hidePapersTab?: boolean;
}

export function usePapersDiscussionsTabs({
  contentUrlKey,
  hidePapersTab
}: UsePapersDiscussionsTabsProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const radioState = useAppSelector(
    (state) => state.tabs.papersDiscussionsRadioState
  );

  const handleRadioStateChange = useCallback(
    (newRadioState: string) => {
      dispatch(setPapersDiscussionsRadioState(newRadioState));
      navigate(createLink(`/${contentUrlKey}/${newRadioState}`));
    },
    [dispatch, navigate, contentUrlKey]
  );

  const handlePapersClick = useCallback(() => {
    handleRadioStateChange('papers');
  }, [handleRadioStateChange]);

  const handleDiscussionsClick = useCallback(() => {
    handleRadioStateChange('discussions');
  }, [handleRadioStateChange]);

  // Adjust the radio state based on the hidePapersTab value
  useEffect(() => {
    if (hidePapersTab && radioState === 'papers') {
      dispatch(setPapersDiscussionsRadioState('discussions'));
    } else if (!hidePapersTab && radioState === 'discussions') {
      dispatch(setPapersDiscussionsRadioState('papers'));
    }
  }, [dispatch, hidePapersTab, radioState]);

  //Change radiostate if url contain state
  useEffect(() => {
    const pathSegments = location.pathname.split('/');
    const newState = pathSegments[pathSegments.length - 1];
    if (newState === 'papers' || newState === 'discussions') {
      dispatch(setPapersDiscussionsRadioState(newState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, location.pathname]);

  return { handlePapersClick, handleDiscussionsClick, radioState };
}
