import { env } from '../../env';
import { IErrorResponse } from '../../types/error.type';

interface TopicPriorityResponse {
  position: number;
}

export const setAndGetTopicRaisePriority = async (
  topicKey: string
): Promise<TopicPriorityResponse> => {
  try {
    const url = `${env.apiUrl}/api/v1/topics/${topicKey}/priority-weight`;
    const auth = JSON.parse(localStorage.getItem('auth') || '{}');
    const response = await fetch(url, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${auth.jwt}`
      }
    });

    if (!response.ok) {
      const errorData: IErrorResponse = await response.json();
      throw new Error(
        errorData.message ||
          `Error: ${response.status} - ${response.statusText}`
      );
    }

    const data: TopicPriorityResponse = await response.json();
    return data;
  } catch (error: any) {
    throw new Error(error.message || 'Failed to fetch data from the server.');
  }
};
